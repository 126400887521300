@import '~/client/src/shared/theme.module';

$sitemaps-left-bar-width: 300px;

.sitemaps-left-bar {
  flex-basis: $sitemaps-left-bar-width;
  max-width: $sitemaps-left-bar-width;
  min-width: $sitemaps-left-bar-width;

  .globe-icon {
    svg path {
      fill: get_color('neutral', 30);
    }
  }
  .create-icon {
    svg path {
      fill: get_color('neutral', 100);
    }

    background-color: get_color('primary', 30);
  }

  .change-menu {
    min-height: 45px;
  }

  .globe-node {
    height: 32px !important;
  }

  .sitemaps-table {
    height: calc(100% - 159px);
  }

  .globe-plan-node {
    // height: 71px !important;
    &.selected {
      background-color: get_color('primary', 96);
    }
    &:hover {
      background-color: get_color('neutral', 90);
    }
  }

  .sitemap-ribbon {
    top: 55px;
    z-index: 10;
    right: 300px;
    .actions-menu {
      min-width: 30px;
      height: 30px;
      box-shadow: 0px 3px 20px -5px get_color('neutral', 50);
    }
    .title-row {
      height: 40px;
      width: 40px;
    }
    .select-dialog-container {
      min-width: 250px;
      max-width: 350px;
    }
    .delivery-attributes-container {
      display: flex;
      .delivery-attributes {
        min-width: 230px;
        .attribute-tag-holder {
          display: inline-flex !important;
          max-width: 300px;
          padding-top: 2px;
          padding-bottom: 2px;
        }

        .assign-sitemap-dialog {
          top: 5px;
        }
      }
    }
    .loader-container {
      min-width: 32px;
    }
    .last-updated-container {
      max-width: 90px;
    }
  }

  .sitemap-image-holder {
    .file-preview {
      height: 50px !important;
      width: 80px !important;
      border-radius: 3px;
      border: 1px solid get_color('neutral', 90);
      background-color: get_color('neutral', 100);
    }
  }

  &.collapsed-panel {
    max-width: 40px !important;
  }

  &-header {
    min-height: 40px;
    .navigation-arrows {
      height: 24px;
    }
  }

  .left-panel-items {
    min-height: 47px;
  }

  .create-new-button {
    background-color: get_color('primary', 30) !important;
    border-color: get_color('primary', 30) !important;
    color: get_color('neutral', 100) !important;

    svg path:first-child {
      fill: none;
      stroke: none;
    }

    svg path:last-child {
      stroke: get_color('neutral', 100);
      fill: get_color('neutral', 100);
    }

    &.active,
    &:hover {
      background-color: get_color('neutral', 100) !important;
      color: get_color('violet', 40) !important;

      svg path:last-child {
        stroke: get_color('violet', 40);
        fill: get_color('violet', 40);
      }
    }
  }

  .all-toggle {
    border: 1px dashed transparent;

    &:hover {
      svg path#eye {
        fill: get_color('neutral', 0);
      }

      background-color: get_color('neutral', 84);
      border-color: get_color('neutral', 0);
    }
  }

  .group-toggle {
    border: 1px dashed get_color('neutral', 60);

    svg path {
      fill: get_color('neutral', 50);
    }

    &:hover {
      background-color: get_color('neutral', 84);
      border-color: get_color('neutral', 0);

      svg {
        background-color: get_color('neutral', 84) !important;
      }
    }
  }

  .icon-wrapper:hover {
    svg path {
      fill: get_color('neutral', 0);
    }
  }

  .group-tooltip-handler {
    right: 40px;
    z-index: 2;
  }
}

.map-option {
  .map-image {
    width: 80px;
    height: 60px;
  }
  &.active {
    background-color: get_color('primary', 30, 0.12);
  }
}

.search-bar {
  .bare-input-appearance {
    padding-left: 35px;
    background-color: get_color('neutral', 100);
    &:hover {
      background-color: get_color('neutral', 92);
    }
    &:focus {
      background-color: get_color('neutral', 96);
    }
  }
  .search-bar-icon {
    left: 20px;
    z-index: 10;
    top: 19px;
    svg path {
      fill: get_color('neutral', 80);
    }
  }
}

.left-panel-items {
  .bp3-popover-target {
    width: 100%;
  }
  .selected-item {
    border-bottom: 3px solid get_color('primary', 30);
  }
  .left-panel-item {
    &.selected {
      &:hover {
        background-color: get_color('blue', 96);
      }
    }
    &.inactive {
      &:hover {
        background-color: get_color('primary', 96);
      }
    }
  }
}

.notification-list {
  i {
    display: inline-flex;
  }

  .notification-read {
    background-color: get_color('grey', 96);
  }

  .notification-item {
    overflow-x: hidden;
    display: flex;
    width: 100%;

    &-content {
      flex-basis: 100%;
      display: flex;
      flex-shrink: 0;
    }

    &-swipe-area {
      padding: 0 60px;
      background-color: get_color('primary', 30);
      display: flex;
      color: get_color('neutral', 100);
    }
  }
}
