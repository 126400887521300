@import '~/client/src/shared/theme.module';

$date-picker-options-width: 150px;

.logistics {
  .permit-side-view {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 400px;
    border-left: 1px solid get_color('neutral', 84);
    background-color: get_color('neutral', 100);
    z-index: 100;
  }

  .desktop-deliveries-details,
  .gant-side-bar {
    z-index: 100 !important;
  }

  .file-fullscreen-preview-container {
    z-index: 200;

    > .file-fullscreen-preview > .cross-icon {
      right: 20px;
      background: get_color('neutral', 84);

      > svg {
        height: 40px !important;
        width: 40px !important;

        path:last-child {
          fill: get_color('primary', 30);
        }
      }
    }
  }

  &-side-bar {
    flex-basis: 420px;
    max-width: 420px;

    $header-height: 32px;
    $sticky-header-offset: $header-height - 1;

    @mixin sticky-header-class($header-index-position, $headers-count) {
      top: $sticky-header-offset * $header-index-position;
      bottom: $sticky-header-offset * ($headers-count - $header-index-position);
    }

    @mixin headers-classes($headers-count) {
      .logistics-side-bar-header {
        &.sticky-header-1 {
          @include sticky-header-class(1, $headers-count);
        }
        &.sticky-header-2 {
          @include sticky-header-class(2, $headers-count);
        }
        &.sticky-header-3 {
          @include sticky-header-class(3, $headers-count);
        }
      }
    }

    &.sticky-headers-count-1 {
      @include headers-classes(1);
    }
    &.sticky-headers-count-2 {
      @include headers-classes(2);
    }
    &.sticky-headers-count-3 {
      @include headers-classes(3);
    }

    &-header {
      z-index: 10;
      height: $header-height;
      min-height: $header-height;
      max-height: $header-height;

      .side-bar-section-icon,
      .side-bar-section-icon > svg {
        width: 20px;
        height: 20px;
        color: get_color('neutral', 100);

        path {
          fill: get_color('neutral', 100);
        }
      }
    }

    .sidebar-deliveries-cards {
      min-height: 50px;
      > div {
        height: unset !important;

        > .compact-deliveries-list {
          height: fit-content !important;
          overflow: hidden !important;
        }
      }
    }

    .activities-tree .ReactVirtualized__Grid.ReactVirtualized__List {
      overflow: hidden !important;
    }
    .activities-tree .activity-item,
    .activities-tree .activity-tree-node {
      height: 220px !important;
      max-height: 220px !important;
    }
  }

  .sitemaps-gallery {
    z-index: 1;

    .selected-sitemap {
      cursor: unset !important;
    }
  }

  .multi-grid-container .cell-header {
    font-weight: 600;
    border-bottom: 2px solid get_color('neutral', 0);
  }

  .status-title {
    height: 42px;
  }
  .delivery-card,
  .workflow-card,
  .activity-item,
  .card-wrapper {
    &.selected-card {
      background-color: get_color('primary', 96);
      box-shadow: 0px 0px 34px 0px get_color('grey', 50);
      .more-less-toggler {
        background-color: inherit !important;
      }
    }
  }

  .table-holder {
    align-items: start;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
  }

  .selection-pop-up {
    z-index: 11;
  }

  .date-picker-options-holder {
    top: 40px;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $date-picker-options-width;
    box-shadow: 0 14px 28px get_color('neutral', 0, 0.25),
      0 10px 10px get_color('neutral', 0, 0.25);

    .option {
      width: 100%;
      height: 32px;

      &:hover {
        background-color: get_color('primary', 96);
      }
      &.active {
        color: get_color('neutral', 100);
        background-color: get_color('primary', 30);
      }
    }
  }

  .show-on-text-box {
    height: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: get_color('neutral', 100);

    .shown-on-icon {
      width: 16px;
      height: 16px;
    }
  }
  .show-on-icons-box {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 30px;
    background-color: get_color('neutral', 92);

    .icon-holder {
      min-width: 20px;
      border-radius: 25px;
      height: 20px;
      background-color: get_color('primary', 30) !important;

      &.de-selected {
        background-color: get_color('neutral', 60) !important;
      }

      .delivery-icon svg {
        background-color: unset !important;
        width: 14px !important;
        height: 12px !important;
        path {
          fill: get_color('neutral', 100);
          stroke: get_color('neutral', 100);
        }
      }

      .activity-icon svg {
        width: 12px !important;
        height: 12px !important;
        path {
          fill: get_color('neutral', 100);
          stroke: get_color('neutral', 100);
        }
      }

      .permit-icon svg {
        width: 14px !important;
        height: 14px !important;
      }
    }
  }

  .weather-widget {
    min-width: 230px;
    flex: none;

    .min-max-temp-range {
      color: get_color('neutral', 50);
      font-size: 10px;
    }

    .desc {
      font-size: 15px;
      color: get_color('neutral', 0);
      text-transform: capitalize;

      .weather-icon,
      .weather-icon svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .header-bar {
    .btn-filter span {
      max-width: 200px !important;
    }

    .logistic-filter {
      flex-basis: 300px;
    }

    .overflowed-filters-list {
      min-width: 200px;
    }

    .overflowed-filters-modal {
      max-height: 480px;
      max-width: 350px;
      z-index: 100;
    }
    &-main-content {
      border-bottom: 1px solid get_color('slate', 90);
    }
  }

  .workflow-filter-tabs-container {
    $forms-tab-container-height: 50px;

    min-height: $forms-tab-container-height;
    max-height: $forms-tab-container-height;
    flex: 1 !important;

    .workflow-filter-tab-item {
      $forms-tab-height: 100%;
      $forms-tab-color: get_color('primary', 50);

      max-width: 200px;
      line-height: 20px;
      height: $forms-tab-height;
      border-bottom: 1px solid get_color('neutral', 100);
      color: get_color('neutral', 50);

      &:hover {
        background-color: get_color('neutral', 92);
        border-color: get_color('neutral', 92);
      }
      &:active {
        background-color: get_color('neutral', 88);
        border-color: get_color('neutral', 88);
        transition: background-color 0.2s ease;
      }

      &.active {
        color: $forms-tab-color;
        border-bottom: 2px solid $forms-tab-color;

        &:hover {
          background-color: get_color('primary', 96);
        }
        &:active {
          background-color: get_color('primary', 92);
          transition: background-color 0.2s ease;
        }
      }
    }
  }

  .avatar-filter {
    padding: 3px;
    gap: 5px;
    white-space: nowrap;
  }
}
